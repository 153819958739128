import React, { useRef, useState } from "react";

import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { navigate } from "gatsby";

import SimpleReactValidator from "simple-react-validator";

let stripePromise;
const getStripe = (stripeAccount) => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK, { stripeAccount });
  }
  return stripePromise;
};
const StripePaymentForm = ({
  paymentMethod,
  setProgress,
  onProcessing,
  order,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const validator = useRef(new SimpleReactValidator());
  const [nameOnCard, setNameOnCard] = useState("");
  const handlePayment = async (e) => {
    e.preventDefault();

    onProcessing(true);

    let formValidated = validator.current.allValid();
    if (!formValidated) {
      onProcessing(false, "Please fill in the required fields.");
      validator.current.showMessages();
      return;
    }

    const { orderId } = order;
    if (!stripe || !elements || !orderId) return;

    // const url = `${ORDERS_BASE_URL}/${orderId}/stripe`;
    // const headers = REQUEST_HEADERS;

    // const response = await fetch(url, { headers });
    // if (response.ok) {
    // const { clientSecret } = await response.json();
    const payload = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: `${window.location.href}&verify=true`,

        shipping: {
          name: `${order.customer.firstName} ${order.customer.lastName}`,
          address: {
            line1: order.customer.address,
            city: order.customer.city,
            state: order.customer.state,
            postal_code: order.customer.zip,
            country: "US",
          },
        },
      },
      //   payment_method: {
      //     card: elements.getElement(CardElement),
      //     billing_details: {
      //       name: this.state.nameOnCard,
      //       email: customer.email,
      //     },
      //   },
    });

    if (payload.error) {
      onProcessing(false, `Payment failed: ${payload.error.message}`);
      return;
    } else {
    }
    console.log("navigate checkout");
    setProgress("success");
    navigate(`${window.location.pathname}?step=success`);
    onProcessing();
  };
  return (
    <form onSubmit={handlePayment}>
      <div className="card-body" style={{ backgroundColor: "#fafafa" }}>
        <div className=" col-sm-12">
          <div className="field-label">Name on card</div>
          <input
            className="form-control"
            type="text"
            name="nameOnCard"
            value={nameOnCard}
            placeholder="Cardholder name"
            onChange={(e) => setNameOnCard(e.target.value)}
            onBlur={() => validator.current.showMessageFor("nameOnCard")}
            style={{ backgroundColor: "#fff" }}
          />
          {validator &&
            validator.current.message(
              "nameOnCard",
              nameOnCard,
              "required|alpha_space"
            )}
        </div>
        <div className="form-group col-sm-12 mb-0">
          <div className="field-label">Card details</div>
          <div
            className="px-3 py-4 rounded bg-white"
            style={{ backgroundColor: "#fff" }}
          >
            <PaymentElement />
          </div>
        </div>
      </div>
      <div className="float-right m-3">
        {paymentMethod === "stripe" && (
          <button
            type="submit"
            className="btn btn-sm btn-primary "
            disabled={!stripe}
            style={{color: "#333333"}}
          >
            Place your order
          </button>
        )}
      </div>
    </form>
  );
};
function StripeCheckout({
  clientSecret,
  metadata,
  paymentMethod,
  order,
  onProcessing,
  setProgress,
}) {
  const stripePromise = getStripe(metadata.stripeAccount);

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <StripePaymentForm
        paymentMethod={paymentMethod}
        order={order}
        onProcessing={onProcessing}
        setProgress={setProgress}
      />
    </Elements>
  );
}

export default StripeCheckout;